import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ResetPasswordModel } from "../models/reset-password.model";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../app.service";
import { passwordValidator } from "../password.validator";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import {
  faTimesCircle,
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ResetPasswordService } from "./reset-password.service";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import { ResetPasswordResultModel } from "../models/reset-password-result.model";
import { PreResetPasswordModel } from "../models/pre-reset-password.model";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  Cross = faTimesCircle;
  Tick = faCheckCircle;
  Triangle = faExclamationTriangle;
  Eye = faEye;
  EyeSlash = faEyeSlash;
  public showPassword: boolean;
  token: string;
  resetPasswordResult: ResetPasswordResultModel;
  @ViewChild("infoTemplate", { static: true })
  public infoTemplateRef: TemplateRef<any>;
  messageTitle: string;
  messageText: string;
  username: string;
  resetPasswordForm: FormGroup;
  okModalRef?: BsModalRef;
  passwordResetTitle: string;
  passwordResetMessage: string;
  hasFocus: boolean;
  private intervalId: any;
  count = 30;
  shouldloadForm : boolean = true;

  constructor(
    private resetPasswordService: ResetPasswordService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private modalService: BsModalService,
    private titleService: Title,
    private translate: TranslateService
  ) {}

  get formControls() {
    return this.resetPasswordForm.controls;
  }

  ngOnInit(): void {
    this.username = this.appService.username;
    this.resetPasswordForm = new FormGroup(
      {
        newPassword: new FormControl("", Validators.required),
        confirmPassword: new FormControl("", Validators.required),
      },
      { validators: passwordValidator }
    );
    this.translate.get("/ResetPassword").subscribe((res) => {
      this.titleService.setTitle("GEP | " + res);
    });
    this.appService.titleKey = "/ResetPassword";

    this.route.queryParams.subscribe((params) => {
      if (params.token) {
        this.token = params.token;
      }
      if (params.userName) {
        this.username = params.userName;
      }
      this.resetPasswordForm.addControl(
        "otp",
        new FormControl("", Validators.required)
      );
      if (!this.username || !this.token) {
        //This is a wrongly constituted link. Dont need to do anything specific as this link is not from the email
      }
    });
    this.translate.get("Reset_Password.PasswordReset").subscribe((res) => {
      this.passwordResetTitle = res;
    });
    this.translate.get("Reset_Password.PasswordResetText").subscribe((res) => {
      this.passwordResetMessage = res;
    });
    this.count = (this.appService.reRequestVerificationCodeSeconds != null 
      && this.appService.reRequestVerificationCodeSeconds != undefined 
      && this.appService.reRequestVerificationCodeSeconds > 0) ? 
      this.appService.reRequestVerificationCodeSeconds : this.count;
    this.resetCounter();

    if(this.appService.errorObject !== null && this.appService.errorObject !== undefined
      && this.appService.errorObject[0] != null && this.appService.errorObject[0] != undefined) {
      this.shouldloadForm = false;
      this.appService.returnUrl = this.appService.errorObject[0].returnUrl;
      this.showMessage("Reset_Password.Error", "ContactSupport");
    }

  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  resetPassword(): void {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    const resetPassword: ResetPasswordModel = {
      userName: this.username,
      password: this.resetPasswordForm.value.newPassword,
      confirmPassword: this.resetPasswordForm.value.confirmPassword,
      token: this.token,
      cultureCode: this.appService.cultureCode,
      otp: this.resetPasswordForm.value.otp,
    };

    this.resetPasswordService.resetPassword(resetPassword).subscribe(
      (resetPasswordResult) => {
        this.resetPasswordResult = resetPasswordResult;
        if (resetPasswordResult.isPasswordReset) {
          this.appService.showSuccess(
            this.passwordResetTitle,
            this.passwordResetMessage
          );
          if (resetPasswordResult.isOpenIdUrl) {
            this.router.navigate(["/Login"], {
              queryParams: { ReturnUrl: resetPasswordResult.returnUrl },
            });
          } else {
            window.location.href = resetPasswordResult.returnUrl;
          }
          return;
        } else if (
          resetPasswordResult.isWrongOtp ||
          resetPasswordResult.isOtpNotProvided
        ) {
          //ideally isOtpNotProvided should not come as the locked status is sent in the link.
          this.showMessage(
            "Reset_Password.Error",
            "Reset_Password.IncorrectOtp"
          );
          return;
        } else if (resetPasswordResult.isTokenExpired) {
          this.showMessage(
            "Reset_Password.Error",
            "Reset_Password.LinkExpired"
          );
          return;
        } else {
          this.resetPasswordFailed(resetPasswordResult);
        }
      },
      () => {
        this.resetPasswordFailed(null);
      }
    );
  }
  private resetPasswordFailed(resetPasswordResult: ResetPasswordResultModel) {
    setTimeout(() => {
      if (resetPasswordResult) {
        if (resetPasswordResult.isReuseOfOldPassword) {
          this.resetPasswordForm.setErrors({ passwordReused: true });
        } else if (resetPasswordResult.isPasswordMismatch) {
          this.resetPasswordForm.setErrors({ passwordMismatch: true });
        } else {
          this.resetPasswordForm.setErrors({ passwordResetFailed: true });
          window.location.href = resetPasswordResult.returnUrl;
        }
      } else {
        this.resetPasswordForm.setErrors({ passwordResetFailed: true });
        window.location.href = resetPasswordResult.returnUrl;
      }
      this.resetPasswordForm.markAsTouched();
    }, 100);
  }

  showMessage(messageHeader: string, messageText: string): void {
    this.messageTitle = messageHeader;
    this.messageText = messageText;
    this.okModalRef = this.modalService.show(this.infoTemplateRef, {
      class: "modal-dialog-centered",
    });
  }
  alertOk(): void {
    if (this.okModalRef) {
      this.okModalRef.hide();
    }
    if (!this.appService.returnUrl) {
      this.appService.returnUrl = this.resetPasswordResult.returnUrl;
    }

    if(this.appService.errorObject !== undefined
        && this.appService.errorObject !== null
        && this.appService.errorObject[0] != null 
        && this.appService.errorObject[0] != undefined) {
      window.location.href = this.appService.errorObject[0].returnUrl;
    }

    if (
      this.resetPasswordResult.isWrongOtp ||
      this.resetPasswordResult.isOtpNotProvided
    ) {
      return;
    }
    if (this.resetPasswordResult.isTokenExpired) {
      this.router.navigate(["/RecoverPassword"], {
        queryParams: { ReturnUrl: this.appService.returnUrl },
      });
      return;
    }
    this.router.navigate(["/Login"], {
      queryParams: { ReturnUrl: this.appService.returnUrl },
    });
  }
  
  resendVerificationCode(): void {
    const request: PreResetPasswordModel = {
      token: this.token ? this.token : null,
    };
    this.resetPasswordService.preResetPassword(request).subscribe((result) => {
      if (result.isOtpSent) {
        this.count = (this.appService.reRequestVerificationCodeSeconds != null 
          && this.appService.reRequestVerificationCodeSeconds != undefined 
          && this.appService.reRequestVerificationCodeSeconds > 0) ? 
          this.appService.reRequestVerificationCodeSeconds : 30;
        this.resetCounter();
      }
    });
  }
  resetCounter() {   
    this.intervalId = setInterval(() => {
      if (this.count > 0) {
        this.count--;
      } else {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }
  onFocus(): void{
    this.hasFocus = true;
  }
  onBlur(): void{
    this.hasFocus = false;
  }
}
