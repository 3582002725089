import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appUsernameValidation]'
})
export class UsernameValidationDirective {

  constructor(private el: ElementRef) { }

  // Allow only alphabets, numbers, at the rate sign, dot sign, and other specified characters
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const allowedChars = [
      97, 122, // a to z
      65, 90,  // A to Z
      48, 57,  // 0 to 9
      46,      // .
      64,      // @
      13,      // Enter
      95,      // _
      47,      // /
      92,      // \
      45,      // -
      35,      // #
      36       // $
    ];

    const charCode = event.key.charCodeAt(0);
    if (allowedChars.includes(charCode) || (charCode >= 97 && charCode <= 122) || (charCode >= 65 && charCode <= 90) || (charCode >= 48 && charCode <= 57)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  @HostListener('paste', ['$event']) sanitizePaste(event: ClipboardEvent) {
    setTimeout(() => {
      const regex = /[!%^&*+=?;:",|<>(){}\[\]]/gi;
      this.el.nativeElement.value = this.el.nativeElement.value.replace(regex, '').replace(' ', '');
      this.checkLength();
    });
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    this.checkLength();
  }

  private checkLength() {
    if (this.el.nativeElement.value.length > 200) {
      this.el.nativeElement.value = this.el.nativeElement.value.slice(0, 200);
    }
  }
}
