import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LoginResultModel } from '../models/login-result.model';

@Component({
  selector: 'app-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.scss']
})
export class AuthenticatorComponent implements OnInit {
  authResult:LoginResultModel;
  hideAuthenticatorDiv: boolean = false;
  constructor( private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService) { 

    }

  ngOnInit(): void {
    this.authResult = this.appService.authResult ;
    this.authResult.authenticator.authenticatorUri = this.appService.aesDecrypt(this.appService.username, this.authResult.authenticator.authenticatorUri);
    this.translate.get("/Authenticator").subscribe((res) => {
      this.titleService.setTitle("GEP | " + res);
    });
    this.appService.titleKey = "/Authenticator";
  }
  onFallbackToSms(event: boolean) {
    this.hideAuthenticatorDiv = event;
  }
}
