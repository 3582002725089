import { HttpClient, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AesEncryptionService } from './encryption/aes-encryption.service';
import { LayoutViewModel } from './models/layout-view.model';
import { ToastrService } from 'ngx-toastr';
import { PartnerModel } from './models/partner.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoginResultModel } from './models/login-result.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  forgotPasswordTab: boolean;
  private flagSelected = new BehaviorSubject<any>(true);
  flagSelection = this.flagSelected.asObservable();

  constructor(private encryptionService: AesEncryptionService, private tokenExtractor: HttpXsrfTokenExtractor, 
    private toastrService: ToastrService, private httpClient: HttpClient, private translate: TranslateService) {
    this.cultureCode = "en-US"; //Default code

    this.translate.get("Error").subscribe(text => {
      this.errorToastTitle = text;
    });
    this.translate.get("ContactSupport").subscribe(text => {
      this.errorToastMessage = text;
    });
  }

  private errorToastTitle: string;
  private errorToastMessage: string;
  private _username: string;
  private _password: string;
  private _returnUrl: string;
  private _organizationName: string;
  private _cultureCode: string;
  private _layoutView: LayoutViewModel;
  private _titleKey : string;
  private _partners: PartnerModel[];
  private _lastname: string;
  private _authResult: LoginResultModel;
  private _firstname: string;
  private _maskedEmail: string;
  private _maskedPhoneNumber: string;
  private _reRequestVerificationCodeSeconds: number;
  private _errorObject: any;

  set authResult (authResult: LoginResultModel) {
    this._authResult = authResult;
  }
  get authResult(){
    return this._authResult;
  }

  set titleKey (titleKey: string) {
    this._titleKey = titleKey;
  }
  get titleKey(){
    return this._titleKey;
  }

  set layoutView(layoutView: LayoutViewModel) {
    this._layoutView = layoutView;
  }
  get layoutView() {
    return this._layoutView;
  }

  set username(username: string) {
    this._username = username;
  }
  get username() {
    return this._username;
  }

  set firstname(firstname: string) {
    this._firstname = firstname;
  }
  get firstname() {
    return this._firstname;
  }

  set lastname(lastname: string) {
    this._lastname = lastname;
  }
  get lastname() {
    return this._lastname;
  }

  set password(password: string) {
    this._password = password;
  }
  get password() {
    return this._password;
  }

  set reRequestVerificationCodeSeconds(reRequestVerificationCodeSeconds: number) {
    this._reRequestVerificationCodeSeconds = reRequestVerificationCodeSeconds;
  }
  get reRequestVerificationCodeSeconds() {
    return this._reRequestVerificationCodeSeconds;
  }

  set returnUrl(returnUrl: string) {
    this._returnUrl = returnUrl;
  }
  get returnUrl() {
    return this._returnUrl;
  }

  set cultureCode(cultureCode: string) {
    this._cultureCode = cultureCode;
  }
  get cultureCode() {
    return this._cultureCode;
  }
  set maskedEmail(maskedEmail: string) {
    this._maskedEmail = maskedEmail;
  }
  get maskedEmail() {
    return this._maskedEmail;
  }

  set maskedPhoneNumber(maskedPhoneNumber: string) {
    this._maskedPhoneNumber = maskedPhoneNumber;
  }
  get maskedPhoneNumber() {
    return this._maskedPhoneNumber;
  }
  set organizationName(organizationName: string) {
    this._organizationName = organizationName;
  }
  get organizationName() {
    return this._organizationName;
  }

  set partners(partners: PartnerModel[]) {
    this._partners = partners;
  }
  get partners() {
    return this._partners;
  }

  aesEncrypt(key, value) {
    this.encryptionService.secretKey = key;
    this.encryptionService.vectorKey = this.tokenExtractor.getToken();
    var encrypted = this.encryptionService.encryptData(value);
    return encrypted;
  }

  aesDecrypt(key, value) {
    this.encryptionService.secretKey = key;
    this.encryptionService.vectorKey = this.tokenExtractor.getToken();
    var encrypted = this.encryptionService.decryptData(value);
    return encrypted;
  }

  aesEncryptNew(secretKey, vectorKey,value) {
    this.encryptionService.secretKey = secretKey;
    this.encryptionService.vectorKey = vectorKey;
    var encrypted = this.encryptionService.encryptData(value);
    return encrypted;
  }
  toastServerError() {
    this.showError(this.errorToastTitle, this.errorToastMessage);
  }
  showSuccess(title: string, message: string) {
    this.toastrService.success(message, title);
  }
  showWarning(title: string, message: string) {
    this.toastrService.warning(message, title);
  }
  showError(title: string, message: string) {
    this.toastrService.error(message, title);
  }

  contextChangeGet(): Observable<any> {   
    return this.httpClient.get<any>("/Account/ContextChangeGet");
  }

  flagSelectionChanged():void{
    this.flagSelected.next(false);
  }

  set errorObject(errorObject: any[]) {
    this._errorObject = errorObject;
  }
  get errorObject() {
    return this._errorObject;
  }
}
